
<ng-container>
  <app-splash-screen></app-splash-screen>
</ng-container> 

<div class="box">

    <div class="p-nav">
        <app-header></app-header>
    </div>

    <div class="p-body">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>

</div>