import { Component, OnInit } from '@angular/core';
import { AllService } from './services/all.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Pravin Bhati';
  splash:boolean;

  constructor(private _all: AllService,private titleService:Title, private metaService: Meta, private activatedRoute: ActivatedRoute, private router: Router ){
    this._all.splash.next(true);
    this._all.splash.subscribe(val=>{
      this.splash=val;
    })
    this._all.splash.next(true);

    this.metaService.updateTag({ name: 'keywords', content: _all.getMetaKeyWords() });
    this.metaService.updateTag({ itemprop: 'keywords', content: _all.getMetaKeyWords() });

  }

  ngOnInit(){

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
 
      var rt = this.getChild(this.activatedRoute);

      rt.data.subscribe(data=>{
        this.titleService.setTitle(data.title);
        if (data.title) {
          this.metaService.updateTag({ name: 'twitter:title', content: data.title });
          this.metaService.updateTag({ property: 'og:title', content: data.title });
        } else {
          this.metaService.removeTag("name='twitter:title'");
          this.metaService.removeTag("property='og:title'");
        }
        if (data.url) {
          this.metaService.updateTag({ property: 'og:url', content: data.url });
        } else {
          this.metaService.removeTag("property='og:url'");
        }
      });
 
    });

  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }


}
