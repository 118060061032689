import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AllService } from 'src/app/services/all.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements AfterViewInit{

  constructor(private _titleService: Title, private _all: AllService){
    this._all.splash.next(true);
  }

  ngOnInit(): void {
    this._titleService.setTitle("Pravin Bhati")
  }
  
  ngAfterViewInit(): void{
    this._all.splash.next(false);
  }

}
