<div class="small-nav">

    <a routerLink="home">
        <img src="assets/images/logo.png" class="logo">
    </a>

    <div class="nav-bar">

        <div class="nav-list">
            <div class="nav-option"><a mat-icon-button color="accent" routerLink="home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="nav-icon" matTooltip="home"> <mat-icon>home</mat-icon> </a></div>
            <div class="nav-option"><a mat-icon-button color="accent" routerLink="about" routerLinkActive="active"  class="nav-icon" matTooltip="About"> <mat-icon>person</mat-icon> </a></div>
            <div class="nav-option"><a mat-icon-button color="accent" routerLink="skills" routerLinkActive="active"  class="nav-icon" matTooltip="Skills & Experiance"> <mat-icon>dashboard</mat-icon> </a></div>
            <div class="nav-option"><a mat-icon-button color="accent" routerLink="work" routerLinkActive="active"  class="nav-icon" matTooltip="Works"> <mat-icon>assignment_turned_in</mat-icon> </a></div>
            <div class="nav-option"><a mat-icon-button color="accent" routerLink="contact" routerLinkActive="active"  class="nav-icon" matTooltip="Contact"> <mat-icon>account_box</mat-icon> </a></div>
        </div>

        <div class="list-sec">
            <button mat-icon-button color="accent" class="list-btn" (click)="this.toggleNav()">
                <mat-icon *ngIf="this.navOpen">close</mat-icon>
                <mat-icon *ngIf="!this.navOpen">dehaze</mat-icon>
            </button> 
        </div>
        

    </div>

</div>
