import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-small-nav',
  templateUrl: './small-nav.component.html',
  styleUrls: ['./small-nav.component.css']
})
export class SmallNavComponent implements OnInit {

  navOpen:boolean=false;

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleNav(){
    if(this.navOpen){
      $(".nav-bar").css("width", "0");
      $(".nav-list").css("width", "0");
      this.navOpen=false;
    }
    else{
      $(".nav-list").css("width", "100%");
      $(".nav-bar").css("width", "100%");
      this.navOpen=true;
    }
  }


}
