import { Component, OnInit } from '@angular/core';
import { AllService } from 'src/app/services/all.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent implements OnInit {

  constructor(private _all: AllService) {
    this._all.splash.subscribe(val=>{
      this.splashStatus=val;
      if(!this.splashStatus){
        this.hideSplash();
      }
      else{
        this.viewSplash();
        this.showSplash=true;
      }
    })
  }

  windowWidth:string;
  showSplash:boolean=true;
  splashStatus:boolean=true;

  ngOnInit(): void {
  }

  hideSplash(){

    setTimeout(()=>{
        this.windowWidth = '-' + window.innerWidth + 'px';
        setTimeout(()=>{
          this.showSplash = false;
        },500);
      },500);

  }

  viewSplash(){
    this.windowWidth='0';
  }

}
