<div class="d-block d-sm-none social">

    <div class="social-option">
        <a href="https://github.com/bpravin7" target="_blank" class="social-icon"><fa-icon [icon]="git"></fa-icon></a>
    </div>

    <div class="social-option">
        <a href="https://www.linkedin.com/in/bpravin/" target="_blank" class="social-icon"><fa-icon [icon]="ln"></fa-icon></a>
    </div>
    
    <div class="social-option">
        <a href="https://twitter.com/Pravin63264708" target="_blank" class="social-icon"><fa-icon [icon]="tw"></fa-icon></a>
    </div>

    <div class="social-option">
        <a href="https://www.instagram.com/b.pravinn" target="_blank" class="social-icon"><fa-icon [icon]="in"></fa-icon></a>
    </div>

</div>