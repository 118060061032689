import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { MatSnackBar } from '@angular/material/snack-bar';
import { metaKeywords } from '../data/meta-data';

@Injectable({
  providedIn: 'root'
})
export class AllService {

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {
  }

  readonly APIUrl="https://pravin-intern.herokuapp.com/api"
  splash:Subject<boolean> = new BehaviorSubject(true);

  sendFeedback(val): Observable<any>{
    return this.http.post(this.APIUrl + '/feedback',val);
  }

  openSnackBar(message: string, action:string) {
    this._snackBar.open(message, action);
  }

  getMetaKeyWords(){
    return metaKeywords.join(', ');
  }

}
