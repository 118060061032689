import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { SmallNavComponent } from './small-nav/small-nav.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({

  declarations: [
    HeaderComponent,
    FooterComponent,
    NavComponent,
    SmallNavComponent,
    SplashScreenComponent,
    PageNotFoundComponent
  ],

  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FontAwesomeModule,
  ],

  exports:[
    HeaderComponent,
    FooterComponent,
    NavComponent,
    SmallNavComponent,
    SplashScreenComponent,
    PageNotFoundComponent
  ]

})

export class BasicModule { }
