import {NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [

  { path: "", redirectTo:"home" ,pathMatch:"full" },

  { 
    path: "home", 
    loadChildren:()=>import('./home/home.module').then(mod=>mod.HomeModule),
    data: {
      title: 'Pravin Bhati',
      url: location.origin+"/home"
    }
  },

  { 
    path: "about", 
    loadChildren:()=>import('./about/about.module').then(mod=>mod.AboutModule),
    data: {
      title: 'About | Pravin Bhati',
      url: location.origin+"/about"
    }
  },

  { 
    path: "skills", 
    loadChildren:()=>import('./skills/skills.module').then(mod=>mod.SkillsModule),
    data: {
      title: 'Skills & Experience | Pravin Bhati',
      url: location.origin+"/skills"
    }
  },

  { 
    path: "work", 
    loadChildren:()=>import('./work/work.module').then(mod=>mod.WorkModule),
    data: {
      title: 'Work | Pravin Bhati',
      url: location.origin+"/work"
    }
  },

  { 
    path: "contact", 
    loadChildren:()=>import('./contact/contact.module').then(mod=>mod.ContactModule),
    data: {
      title: 'Contact | Pravin Bhati',
      url: location.origin+"/contact"
    }
  },

  { 
    path: "**", 
    redirectTo:"home"
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    { preloadingStrategy: PreloadAllModules, useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
