<div>
    <ul class="box">

        <li class="logo-tab">
            <a routerLink="home"> <img src="assets/images/logo.png" class="logo"> </a>
        </li>

        <li class="nav-tab">
            <ul class="nav-list">
                <li class="nav-option"><a mat-icon-button color="accent" routerLink="home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="nav-icon" matTooltip="home"> <mat-icon>home</mat-icon> </a></li>
                <li class="nav-option"><a mat-icon-button color="accent" routerLink="about" routerLinkActive="active" class="nav-icon" matTooltip="About"> <mat-icon>person</mat-icon> </a></li>
                <li class="nav-option"><a mat-icon-button color="accent" routerLink="skills" routerLinkActive="active" class="nav-icon" matTooltip="Skills & Experiance"> <mat-icon>dashboard</mat-icon> </a></li>
                <li class="nav-option"><a mat-icon-button color="accent" routerLink="work" routerLinkActive="active" class="nav-icon" matTooltip="Works"> <mat-icon>assignment_turned_in</mat-icon> </a></li>
                <li class="nav-option"><a mat-icon-button color="accent" routerLink="contact" routerLinkActive="active" class="nav-icon" matTooltip="Contact"> <mat-icon>account_box</mat-icon> </a></li>
            </ul>
        </li>

        <li class="social-tab">
            <ul class="social-list">
                <li class="social-option"><a href="https://github.com/bpravin7" target="_blank" class="social-icon"><fa-icon [icon]="git"></fa-icon></a></li>
                <li class="social-option"><a href="https://www.linkedin.com/in/bpravin/" target="_blank" class="social-icon"><fa-icon [icon]="ln"></fa-icon></a></li>
                <li class="social-option"><a href="https://twitter.com/Pravin63264708" target="_blank" class="social-icon"><fa-icon [icon]="tw"></fa-icon></a></li>
                <li class="social-option"><a href="https://www.instagram.com/b.pravinn" target="_blank" class="social-icon"><fa-icon [icon]="in"></fa-icon></a></li>
            </ul>
        </li>
        
    </ul>
</div>
